.complaints__list {
    display: flex;
    justify-content: center;
    font-size: 16px;
    line-height: 1.4;
    color: #372717;
    box-sizing: border-box;
    font-family: 'Josefin Sans', sans-serif;
}

.accordion__text .complaints__list {
    padding: 5px 0px !important;
    padding-left: 1em !important;
    font-family: 'Josefin Sans', sans-serif;
    padding-right: 1em !important;
}

.complaints__list .accordion {
    width: 100%;
}

.complaints__list h2 {
    font-size: 24px;
    color: 1689FF;
    line-height: 1.1;
    margin-bottom: 1.5em;
}

.complaints__list .panel {
    border-bottom: 1px solid #ddd;
}

.complaints__list .panel__label {
    position: relative;
    display: block;
    width: 100%;
    color: 1689FF;
    background: none;
    border: none;
    text-align: left;
    padding: 12px 60px 25px 0px;
    font-weight: 500;
    font-size: 20px;
    font-family: inherit;
    transition: color 0.2s linear;
    cursor: pointer;
}

.complaints__list .panel__label:focus {
    outline: none;
}
.complaints__list .panel__label span{
    background-color: #3b91e2;
    color:#fff;
    padding: 5px;
    border-radius: 2px;
    box-shadow: 1px 1px 4px 2px rgb(168, 168, 168);
    cursor: pointer;
    margin-right: -30px;
}
.complaints__list .panel__label span:hover{
    cursor: pointer;
}
.complaints__list .panel__label:after,
.complaints__list .panel__label:before {
    /* content: '';
    position: absolute;
    right: 0px;
    top: 50%;
    width: 22px;
    height: 2px;
    margin-top: -2px;
    background-color: #3b91e2; */
}

.complaints__list .panel__label:before {
    transform: rotate(-90deg);
    transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.complaints__list .panel[aria-expanded='true'] .panel__content {
    opacity: 1;
}

.complaints__list .panel[aria-expanded='true'] .panel__label {
    color: #6cb33d;
}

.complaints__list .panel[aria-expanded='true'] .panel__label:before {
    transform: rotate(0deg);
}

.complaints__list .panel__inner {
    overflow: hidden;
    will-change: height;
    transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.complaints__list .panel__content {
    margin: 0px 0px 25px;
    font-size: 16px;
    color: #767683;
    opacity: 0;
    transition: opacity 0.3s linear 0.18s;
}

.complaints__list .panel:not(:last-child) {
    margin-bottom: 3px;
}