
 /* Give the document some structure. */


/* Create a responsive, centered, three-column grid. */
.card-container {
	align-items: start;
	display: grid;
	grid-gap: 16px;
	grid-template-columns: repeat(auto-fit, 350px);
	justify-content: center;
}


body {
	font-family: sans-serif;
}

header,
footer {
	text-align: center;
}
.card :hover{
    cursor: pointer;
}
.card {
	background-color: '#fff';
	border-radius: 5px;
	color: rgb(70, 164, 241);
	padding: 24px 0 16px;
	text-align: center;
	text-shadow: 1px 1px 0 #555;
}


.back_btn{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-left: 10px;
}

.back_btn :hover{
    cursor: pointer;
    color: blue;
}