* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
}

a {
  color: #333;
  text-decoration: none;
}

.text-label {
  margin-top: 10px;
  position: relative;
}
.report-card {
  height: 150px;
  width: 180px;
}

.box {
  margin: 10px;
}

.margin-box {
  margin-right: 20px;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.margin-class {
  margin-right: 20px;
  width: 70%;
  display: inline;
}
th {
  font-weight: bold;
}

.number-label {
  font-weight: bold;
  /* font-size:xx-large; */
  font-size: 50px;
}

.text {
  font-size:12px;
}

.input-label {
  color: rgb(92, 92, 92);
}

.my-dropdown-toggle::after {
  color: aliceblue;
  margin-left: 10px;
}

.card-margin {
  padding: 20px;
}

.pg__green {
  color: "rgba(70,179,84,.10196078431372549)"
}

.loading_overlay_content {
  top: 45%;
  left: 35%;
  position: fixed;
}

.subtitle text {
  text-decoration: underline;
  font-size: 12px;
}

.heading {
  font-size: 20px;
  text-align: center;
  color:rgb(58, 173, 173);
}

text.title {
  font-size: 25px;
  fill: rgb(58, 173, 173);
}

.chart-card {
  width: 400px;
  height: 400px;
}

path.line {
  fill: none;
  stroke: blue;
  stroke-width: 2px;
}

path.area {
  fill: none;
}

.axis path,
.axis line {
  fill: none;
  stroke: slategray;
  shape-rendering: crispEdges;
}

line.x,
line.y,
circle.y {
  stroke: rgb(87, 182, 182);
  background-color: seagreen;
}

#fbo_pie, #district_pie, #foodissue_pie {
  font-size: x-small;
  font-weight: lighter;
}

#fbo_tooltip, #foodissue_tip, #district_tip {
  position: absolute;
  width: 200px;
  height: auto;
  padding: 10px;
  font-size: 12px;
  color: black;
  background-color: white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid #ddd;
  -webkit-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  -mox-box-shadow: 4px 4px 4px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 4px 4px 10px rbga(0, 0, 0, 0.4);
  pointer-events: none;
}

#fbo_tooltip.hidden, #foodissue_tip.hidden, #district_tip.hidden {
  opacity: 0;
}

#fbo_tooltip p, #foodissue_tip p, #district_tip p {
  margin: 0;
  font-family: sans-serif;
  font-size: 16px;
  line-height: 20px;
}
.d3-tip {
  line-height: 1;
  font-weight: bold;
  padding: 8px;
  font-size: 14px;
  background-color: white;
  border: 1px solid #ddd;
  color: #858796;
  border-radius: 2px;
}
/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: rgba(0, 0, 0, 0.8);
  content: "\25BC";
  position: absolute;
  text-align: center;
}
/* Style northward tooltips differently */
.d3-tip.n:after {
  margin: -1px 0 0 0;
  top: 100%;
  left: 0;
}