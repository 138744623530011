@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");

/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion__section .accordion__accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

.faqs__list__container .accordion__section .accordion__accordion {
  background-color: #ffffff;
}

.faqs__list__container .accordion__section {
  border: 1px solid #ddd !important;
}

.faqs__list__container .accordion__section:last-child .accordion__accordion.active {
  border-bottom-left-radius: unset !important;
  border-bottom-right-radius: unset !important;
}

.faqs__list__container .accordion__section:first-child,
.faqs__list__container .accordion__section:first-child .accordion__accordion {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.faqs__list__container .accordion__section:last-child,
.faqs__list__container .accordion__section:last-child .accordion__accordion,
.faqs__list__container .accordion__section:last-child .accordion__content {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.faqs__list__container .accordion__title {
  font-size: 23px;
  font-weight: normal;
  color: #1689FF;
}

.faqs__list__container .accordion__text {
  background: #3d83d305;
}

.faqs__list__container .faq__list .panel:last-child {
  border-bottom: unset !important;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion__section .accordion__accordion:hover,
.accordion__section .accordion__accordion:active,
.accordion__section .active {
  background-color: #ccc;
  border: none;
  outline: none;
}

.faqs__list__container .accordion__section .accordion__accordion:hover,
.faqs__list__container .accordion__section .accordion__accordion:active,
.faqs__list__container .accordion__section .active {
  background-color: #ffffff;
  border: none;
  outline: none;
}

.accordion__section button:focus {
  outline: none !important;
}

/* Style the accordion content title */
.accordion__title {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: white;
  overflow: hidden;
  transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion__text {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}